function ErrorModal() {
  const getAuthErrorMessage:any = localStorage.getItem("authErrorMessage")
  const message = JSON.parse(getAuthErrorMessage)
  
  return (
    <div className='error-page'>
      <div>
        <h1 className='fw-700'>{message}</h1>
        <h3>このページを参照する権限がありません。</h3>
      </div>
    </div>
  )
}

export default ErrorModal