import axiosInstance from '../axios/axios';

const postToken = async () => {
    // extract token and id from url
    let search = window.location.search.substring(1);
    let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    const token = params?.token

    try {
        const getToken: any = localStorage.getItem("accessToken");
        const accessToken = JSON.parse(getToken);
        const response = await axiosInstance.post('/verify-token', '', {
            headers: {
                Authorization: `Bearer ${token}`,
                AccessToken: accessToken,
                session_id: localStorage.getItem("session_id"),
            }
        });
        if (response.status === 200) {
            return true;
        }
        return true

    } catch (error:any) {
        localStorage.setItem("authErrorMessage", JSON.stringify(error?.response?.data?.errors[0].title))
        window.location.replace("/401")
        return false
    }
}


export default postToken