import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorModal from "../components/errorModal";
import MainLayout from "../components/mainLayout";

function Routes() {
  
  return (
    <Switch>
      <Route path='/' exact >
        <MainLayout />
      </Route>
      <Route exact path='/401' component={ErrorModal} />
    </Switch>
  );
}

export default Routes;
