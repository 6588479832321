import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/fonts/fonts.css";
import Routes from "./routes";

function App() {
  return (
    <BrowserRouter>
        <Provider store={store}>
          <Routes />
        </Provider>
    </BrowserRouter>
  );
}
export default App;
